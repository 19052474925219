import React from 'react'
import styled from 'styled-components'

import { Container } from '@util/standard'
import { MOBILE_BREAKPOINT } from '@util/constants'


interface Props {
  open: boolean;
  darkVersion: boolean;
}


const Line = styled.div<{ top?: boolean; bottom?: boolean; animState: boolean; dark: boolean; }>`
${({ dark }) => dark ? `background-color: black;` : `background-color: white;`};
position: absolute;
width: 100%;
height: 3px;
${({ top }) => top && `top: 0px;`}
${({ bottom }) => bottom && `bottom: 0px;`}
${({ animState, top }) => (animState && top) && `
top: 50%; 
transform: translateY(-50%) rotateZ(45deg);;
`};

${({ animState, bottom }) => (animState && bottom) && `
bottom: 50%; 
transform: translateY(50%) rotateZ(-45deg);
`};


@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  height: 2px;
}

transition: all 0.2s;

`

function OpenCloseIcon(props: Props) {

  const { open, darkVersion } = props

  return (
    <Container position="relative" height="17px" width="37px" cursor="pointer" mobileWidth="25px" mobileHeight="11px" zIndex={3}>
      <Line top animState={open} dark={darkVersion}></Line>
      <Line bottom animState={open} dark={darkVersion}></Line>
    </Container>
  )
}

export default OpenCloseIcon
