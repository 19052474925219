import React, {
  ReactNode,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { Container } from "@util/standard";
import { SanityImage } from "@graphql-types";
import {
  colors,
  icons,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from "@util/constants";
import { Video } from ".";
import ModalBox from "../modalBox";

interface Props {
  videoUrl: string;
  children: ReactNode;
  thumbnail?: SanityImage | null | undefined;
  playOnOpen?: boolean;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const VideoContainer = styled(Container)<{
  expandedViewOn: boolean;
}>`
  width: 98%;
  transition: all 0.5s;
`;

const ButtonContainer = styled(Container)<{ isRight?: boolean }>`
  position: absolute;
  ${({ isRight }) => (isRight ? `right: 0px;` : `left: 0px;`)};
  top: 0px;
  z-index: 3;

  @media only screen and (min-width: ${LAPTOP_BREAKPOINT}px) {
    top: -3vh;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: -24px;
  }
`;

const Thumbnail = styled(Img)`
  width: 100%;
  height: 95%;
  top: 5%;

  @media only screen and (min-width: ${LAPTOP_BREAKPOINT}px) {
    height: 100%;
    top: 0px;
  }
`;

const PlayButton = styled.img`
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -47%);
  z-index: 3;
  opacity: 1;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }

  transition: all 0.2s;
`;

function VideoPopUp(props: Props) {
  const { videoUrl, thumbnail, children, playOnOpen, visible, setVisible } =
    props;

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (playOnOpen) {
      setVideoPlaying(true);
    }
  });

  return (
    <ModalBox
      visible={visible}
      setVisible={setVisible}
      width="95%"
      overflow="visible"
      background="transparent"
    >
      <VideoContainer expandedViewOn={isExpanded}>
        <Container width="100%" height="100%">
          {videoPlaying ? (
            <Video
              url={videoUrl}
              id={videoUrl}
              height="100%"
              width="100%"
              minHeight="0px"
              minWidth="0px"
              volume={true}
              pause={false}
              autoPlayOnLoad
            />
          ) : (
            <>
              {thumbnail && <Thumbnail fluid={thumbnail?.asset?.fluid} />}
              <PlayButton
                src={icons.playButton}
                onClick={() => setVideoPlaying(true)}
              />
            </>
          )}
        </Container>
      </VideoContainer>
    </ModalBox>
  );
}

export default VideoPopUp;
