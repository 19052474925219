import styled from "styled-components";

import {
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container } from "@util/standard";

export const PreviewContainer = styled(Container)<{
  height?: string;
  borderWidth?: number;
  mobileHeight?: string;
  imacHeight?: string;
  ipadProHeight?: string;
}>`
  border: ${(props) => (props.borderWidth ? props.borderWidth : 3)}px solid
    white;
  height: ${(props) => (props.height ? `${props.height}vh` : "70vh")};
  border-bottom-width: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    height: ${(props) => props.imacHeight} !important;
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    height: ${(props) => props.ipadProHeight} !important;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-right: none;
    border-left: none;
    height: 87vh !important;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${(props) =>
      props.mobileHeight
        ? `${props.mobileHeight} !important;`
        : `73vh !important;`};
  }
`;

export const StyledContainer = styled(Container)<{ hoverEffect?: boolean }>`
  visibility: hidden;
  opacity: 0;
  height: 0px;

  ${({ hoverEffect }) =>
    hoverEffect && `visibility: inherit; opacity: 1; height: 20px;`};
  transition: all 0.2s;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    opacity: 1;
    height: 50px;
    visibility: inherit;

    .descriptionP {
      display: none;
    }
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BackgroundOverlay = styled(BackgroundContainer)<{
  gradientHover: number | undefined;
}>`
  overflow: visible;
  background: ${(props) =>
    props.gradientHover == 0
      ? `linear-gradient( 90deg,#0D162F 32.56%, rgba(13, 22, 47, 0.44) 100% )`
      : props.gradientHover == 1
      ? `linear-gradient(
      90deg,rgba(13, 22, 47, 0.44) 0.66%,
      #0d162f 32.79%,
      #0d162f 65.51%,
     rgba(13, 22, 47, 0.44) 100%
    )`
      : props.gradientHover == 2
      ? `linear-gradient( 90deg,rgba(13, 22, 47, 0.44) 10.72%, #0D162F 61.64%, #0D162F 100.94%, rgba(13, 22, 47, 0.44) 100% )`
      : `rgba(13, 22, 47, 0.74)`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    background: rgba(13, 22, 47, 0.74);
  }
`;
