import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

import { Container } from "@util/standard";
import { IconButton, OpenCloseIcon, ShareButton } from "@sub";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import HeaderSideMenu from "./headerSideMenu";
import { Query } from "@graphql-types";

interface Props {
  currentPathname: string;
  setCheckMenu: Dispatch<SetStateAction<boolean>>;
  checkMenu: boolean;
  shareButtonVisible?: boolean;
}

const Logo = styled.img<{ darkVersion: boolean }>`
  width: 52px;
  ${({ darkVersion }) =>
    darkVersion ? `filter:brightness(0.6);` : "filter: none;"};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 31px;
    margin: auto;
  }

  transition: all 0.2s;
`;

const MenuContainer = styled(Container)`
  position: absolute;
  right: 32px;
  top: 35px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    top: 26px;
  }
`;

const BackButtonContainer = styled(Container)`
  position: absolute;
  left: 100px;
  top: 30px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    top: 24px;
    left: 62px;
  }
`;

const Header = (props: Props) => {
  const { currentPathname, setCheckMenu, checkMenu, shareButtonVisible } =
    props;
  const [userScrolled, setUserScrolled] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);

  const { sanityHeader }: Query = useStaticQuery(graphql`
    {
      sanityHeader {
        headerLogo {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
          }
        }
      }
    }
  `);

  if (sanityHeader == null) {
    return null;
  }
  const { headerLogo } = sanityHeader;

  const isDarkVersion = userScrolled && !isHomePage && !checkMenu;

  const checkForScroll = () => {
    window.scrollY > 1000 ? setUserScrolled(true) : setUserScrolled(false);
  };

  const checkIsHomePage = () => {
    currentPathname === "/" ? setIsHomePage(true) : setIsHomePage(false);
  };

  const isProductionPage = currentPathname.includes("our-product");
  const isHistoryPage = currentPathname.includes("history");
  const isProcessPage = currentPathname.includes("production-process");

  const displayShareButton =
    currentPathname.includes("production-process") ||
    currentPathname.includes("our-product") ||
    currentPathname.includes("people");

  useEffect(() => {
    checkForScroll();
    checkIsHomePage();
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", checkForScroll);
    }
    return () => {
      window.removeEventListener("scroll", checkForScroll);
    };
  }, []);

  return (
    <Container
      position="fixed"
      top="0px"
      width="100%"
      height="100px"
      mobileHeight="80px"
      zIndex={checkMenu ? 5 : 4}
      backgroundColor={
        userScrolled && currentPathname.includes("history")
          ? // TEMPORARILY DISABLING TIMELINE LOCK SO NO WHITE BACKGROUND NEEDED
            "transparent"
          : "transparent"
      }
    >
      {currentPathname.includes("history") && currentPathname.length > 9 && (
        <BackButtonContainer>
          {userScrolled && (
            <IconButton
              buttonText="back"
              darkVersion
              reversed
              icon="leftIcon"
              url="/history"
            />
          )}
        </BackButtonContainer>
      )}
      <Container
        position="fixed"
        left="21px"
        top="12px"
        opacity={0.8}
        zIndex={99}
      >
        <Link to="/">
          <Logo
            darkVersion={isDarkVersion}
            src={headerLogo?.asset?.fluid?.src}
          />
        </Link>
      </Container>
      {displayShareButton && (
        <Container
          position="absolute"
          right="150px"
          top="7px"
          width="15%"
          hideOnMobile
        >
          <ShareButton isDark alignment="flex-end" copyEntireUrl />
        </Container>
      )}

      <MenuContainer onClick={() => setCheckMenu(!checkMenu)}>
        <OpenCloseIcon open={checkMenu} darkVersion={isDarkVersion} />
      </MenuContainer>
      {checkMenu && <HeaderSideMenu setSideMenuOpen={setCheckMenu} />}
    </Container>
  );
};

export default Header;
