import styled from "styled-components";

import {
  colors,
  fontSizes,
  icons,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from "./constants";
import {
  AlignItems,
  AlignSelf,
  FlexDirection,
  FontFam,
  JustifyContent,
  Overflow,
  PDisplayType,
  FlexWrap,
  Cursor,
  FontWeight,
  WhiteSpace,
  Color,
  Dimensions,
} from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${(props) => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colors.black};
  opacity: 0.8;
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  tabletFontSize?: number;
  fontFamily?: FontFam;
  noMargin?: boolean;
  width?: string;
  maxWidth?: string;
  mobileWidth?: string;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeight;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: PDisplayType;
  whiteSpace?: WhiteSpace;
  cursor?: Cursor;
  padding?: number;
  textAlign?: string;
  letterSpacing?: string;
  margin?: string;
  mobileMargin?: string;
  tabletWidth?: string;
  tabletMargin?: string;
  laptopMargin?: string;
  imacMargin?: string;
}>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "regular")};
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ color }) =>
    color ? `color: ${colors[color]};` : `color: ${colors.white};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  @media only screen and (min-width: ${LAPTOP_BREAKPOINT}px) {
    ${({ laptopMargin }) => laptopMargin && `margin: ${laptopMargin};`};
  }

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    ${({ imacMargin }) => imacMargin && `margin: ${imacMargin};`};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletFontSize }) =>
      tabletFontSize && `font-size: ${tabletFontSize}px !important;`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.p.mobile}px;
    line-height: ${fontSizes.p.mobile * 1.2}px;
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
  }
  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
}>`
  display: block;
  color: ${(props) => (props.color ? colors[props.color] : colors.white)};
  ${({ underlined }) =>
    underlined ? "text-decoration: underline;" : "text-decoration: none;"}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const H1 = styled.h1<{
  fontFamily?: FontFam;
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: Color;
  margin?: string;
  width?: string;
  letterSpacing?: string;
  mobileStyling?: string;
  mobileFontSize?: number;
  mobileMargin?: string;
  lineHeight?: number;
  mobileLineHeight?: number;
  opacity?: number;
}>`
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "extra-bold"};
  color: ${(props) => (props.color ? colors[props.color] : colors.white)};
  font-size: ${(props) => props.fontSize ?? fontSizes.h1.default}px;
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width};`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ lineHeight }) =>
    lineHeight ? `line-height: ${lineHeight}px;` : `line-height: 61px;`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ letterSpacing }) =>
    letterSpacing
      ? `letter-spacing: ${letterSpacing};`
      : `letter-spacing: 3px;`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileFontSize }) =>
      mobileFontSize
        ? `font-size: ${mobileFontSize}px;`
        : `font-size: ${fontSizes.h1.mobile}px;`};
    line-height: ${fontSizes.h1.mobile * 1.1}px;
    ${({ mobileLineHeight }) =>
      mobileLineHeight && `line-height: ${mobileLineHeight}px;`};
    ${({ mobileStyling }) => mobileStyling && `${mobileStyling};`};
    letter-spacing: 5px;
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
  }
`;
export const H2 = styled.h2<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeight;
  fontSize?: number;
  mobileFontSize?: number;
  fontFamily?: FontFam;
  opacity?: number;
  letterSpacing?: string;
  mobileStyling?: string;
  width?: string;
  mobileWidth?: string;
  lineHeight?: number;
  mobileLineHeight?: number;
}>`
  ${({ width }) => width && `width: ${width};`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "bold")};
  font-size: ${fontSizes.h2.default}px;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`};

  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`};
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
    ${({ letterSpacing }) =>
    letterSpacing
      ? `letter-spacing: ${letterSpacing};`
      : `letter-spacing: 3px;`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;
    line-height: ${fontSizes.h2.mobile * 1.1}px;
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    ${({ mobileFontSize }) =>
      mobileFontSize && `font-size: ${mobileFontSize}px`};
    ${({ mobileStyling }) => mobileStyling && `${mobileStyling};`};
    ${({ mobileLineHeight }) =>
      mobileLineHeight
        ? `line-height: ${mobileLineHeight}px;`
        : "line-height: 30px;"};
  }
`;

export const H3 = styled.h3<{
  color?: Color;
  margin?: string;
  width?: string;
  mobileWidth?: string;
  fontWeight?: FontWeight;
  fontFamily?: FontFam;
  fontSize?: number;
  mobileFontSize?: number;
  opacity?: number;
  letterSpacing?: string;
  lineHeight?: string;
  mobileLineHeight?: number;
  textAlign?: string;
  ipadProWidth?: string;
  tabletWidth?: string;
  ipadProFontSize?: number;
}>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "bold")};
  ${({ fontSize }) =>
    fontSize
      ? `font-size: ${fontSize}px;`
      : `font-size: ${fontSizes.h3.default}px;`};
  ${({ letterSpacing }) =>
    letterSpacing
      ? `letter-spacing: ${letterSpacing};`
      : `letter-spacing: 3px;`};
  ${({ lineHeight }) =>
    lineHeight ? `line-height: ${lineHeight};` : "line-height: 35px;"};
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  @media screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ ipadProWidth }) => ipadProWidth && `width: ${ipadProWidth};`}
    ${({ ipadProFontSize }) =>
      ipadProFontSize && `font-size: ${ipadProFontSize}px;`}
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h3.mobile}px;
    ${({ mobileFontSize }) =>
      mobileFontSize && `font-size: ${mobileFontSize}px;`};
    letter-spacing: 3px;
    ${({ mobileLineHeight }) =>
      mobileLineHeight
        ? `line-height: ${mobileLineHeight}px;`
        : "line-height: 35px;"};
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid
    ${(props) => (props.color ? colors[props.color] : colors.black)};
  height: ${(props) => (props.height ? props.height : `auto`)};
  width: ${(props) => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${(props) => props.thickness ?? 1}px solid
    ${(props) => (props.color ? colors[props.color] : colors.black)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${(props) => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ width }) => width && `width: ${width};`}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: FlexDirection;
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: FlexWrap;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: AlignSelf;
  mobileAlignSelf?: AlignSelf;
  borderRadius?: number;
  overflow?: Overflow;
  padding?: string;
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  mobileJustifyContent?: JustifyContent;
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  mobileMargin?: string;
  cursor?: Cursor;
  reverseDirection?: boolean | null;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  translateX?: string;
  translateY?: string;
  display?: string;
  position?: string;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  mobileStyling?: string;
  tabletStyling?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  tabletWidth?: string;
  tabletHeight?: string;
  tabletMargin?: string;
  tabletPadding?: string;
  tabletAlignItems?: AlignItems;
}>`
  ${({ display }) => (display ? `display: ${display};` : `display: block;`)}
  flex-direction: ${(props) => props.flexDirection ?? "initial"};
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items: ${(props) => props.alignItems ?? "initial"};
  align-self: ${(props) => props.alignSelf ?? "initial"};
  overflow: ${(props) => props.overflow ?? "initial"};
  border-radius: ${(props) => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: 0px ${paddingHorizontal}px 0px ${paddingHorizontal}px;`}
    ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: ${paddingVertical}px 0px ${paddingVertical}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ reverseDirection }) =>
    reverseDirection && `flex-direction: row-reverse;`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}
  ${({ translateY }) => translateY && `transform: translateY(${translateY});`}
  ${({ translateX }) => translateX && `transform: translateX(${translateX});`}
  ${({ position }) => position && `position: ${position};`};
  ${({ top }) => top && `top: ${top};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ left }) => left && `left: ${left};`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletStyling }) => tabletStyling && `${tabletStyling}`}
    align-items: ${(props) => props.tabletAlignItems ?? "initial"};
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}
    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}
    ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.height &&
      `height: ${tabletDimensions.height};`}
  ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.width &&
      `width: ${tabletDimensions.width};`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`}
      ${({ tabletHeight }) => tabletHeight && `height: ${tabletHeight};`}
      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`}
      ${({ tabletPadding }) => tabletPadding && `padding: ${tabletPadding};`}
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.height &&
      `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.width &&
      `width: ${mobileDimensions.width};`}
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
    ${({ mobileAlignSelf }) =>
      mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
      ${({ mobileHeight }) => mobileHeight && `height: ${mobileHeight};`}
      ${({ mobileStyling }) => mobileStyling && `${mobileStyling};`}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  backgroundColor?: Color;
  width?: string;
  height?: string;
  minHeight?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: number;
  rowGap?: number;
  mobileColumnGap?: number;
  rtl?: boolean;
  position?: string;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  maxWidth?: string;
  minWidth?: string;
  tabletMinHeight?: string;
  mobileStyling?: string;
  tabletStyling?: string;
  tabletWidth?: string;
  tabletMinWidth?: string;
  mobileWidth?: string;
  mobileMinWidth?: string;
  mobileMargin?: string;
  tabletMargin?: string;
  ipadProMargin?: string;
  cursor?: string;
  zIndex?: number;
}>`
  display: grid;
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns
      ? `grid-template-columns: ${gridTemplateColumns};`
      : `grid-template-columns: repeat(4,1fr);`};
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};
  ${({ rtl }) => rtl && `direction: rtl;`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
  ${({ position }) => position && `position: ${position};`};
  ${({ top }) => top && `top: ${top};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ left }) => left && `left: ${left};`};
  ${({ cursor }) => cursor && `cursor: ${cursor};`};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    ${({ ipadProMargin }) => ipadProMargin && `margin: ${ipadProMargin};`}
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.tabletRepeat ?? 2}, 1fr);
    ${({ tabletStyling }) => tabletStyling && `${tabletStyling}`}
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`}
    ${({ tabletMinWidth }) => tabletMinWidth && `min-width: ${tabletMinWidth};`}
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`}
    ${({ tabletMinHeight }) =>
      tabletMinHeight && `min-height: ${tabletMinHeight};`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileColumnGap }) =>
      mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    grid-template-columns: repeat(${(props) => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileStyling }) => mobileStyling && `${mobileStyling};`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
    ${({ mobileMinWidth }) => mobileMinWidth && `min-width: ${mobileMinWidth};`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${(props) => props.space ?? 10}px;
`;
