export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  offWhite: "D2D8DD",
  black: "#000",
  grey: "#C2C3C5",
  navy: "#0D162F",
  darkGrey: "#40444A",
  dimgray: "#696969",
  darkNavy: "#01050F",
  beige: "#F9F9F9",
};

export const vimeoUrl = {
  background: (url: string) =>
    `https://player.vimeo.com/video/${url}?background=1&autoplay=1&loop=1&byline=1&title=0&muted=1`,
};

export const fontSizes = {
  p: { default: 16, mobile: 12 },
  h1: { default: 100, mobile: 63 },
  h2: { default: 32, mobile: 23 },
  h3: { default: 30, mobile: 25 },
  h6: { default: 18, mobile: 14 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {};

export const icons = {
  hamburger: require("../../static/icons/hamburger.svg"),
  leftIcon: require("../../static/icons/leftIcon.svg"),
  rightIcon: require("../../static/icons/rightIcon.svg"),
  downIcon: require("../../static/icons/downIcon.svg"),
  playButton: require("../../static/icons/playButton.svg"),
  closeIcon: require("../../static/icons/closeIcon.svg"),
};

export const animationTypes = {
  fadeIn: {
    to: { opacity: 1 },
    from: { opacity: 0 },
  },
  fadeOut: {
    to: { opacity: 0 },
    from: { opacity: 1 },
  },
  slideInLeft: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(-200px)", opacity: 0 },
  },
  slideInRight: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(200px)", opacity: 0 },
  },
  slideInTop: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(-200px)", opacity: 0 },
  },
  slideInBottom: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(50px)", opacity: 0 },
  },
  rotateLeft: {
    to: { transform: "rotateZ(0deg)" },
    from: { transform: "rotateZ(360deg)" },
  },
  rotateRight: {
    to: { transform: "rotateZ(360deg)" },
    from: { transform: "rotateZ(0deg)" },
  },
  noAnimation: {
    to: { opacity: 1 },
    from: { opacity: 1 },
  },
};
