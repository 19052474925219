import React, { ReactNode, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Header from "./header/header";
import Footer from "./footer";
import { fontSizes, TABLET_BREAKPOINT } from "@util/constants";
import BottomNav from "./bottomNav";
import ScreenWidthProvider from "./screenWidthProvider";
interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle<{ scrollLocked: boolean }>`
  body {
    margin: 0px;
    font-family: "regular";
    font-size: ${fontSizes.p}px;
    ${({ scrollLocked }) =>
      scrollLocked ? `overflow: hidden;` : `overflow: initial;`};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    body {
    overflow: initial;
    }
  }
`;

const Main = styled.main<{}>`
  overflow-x: hidden;
`;

function Layout(props: Props) {
  const { children } = props;
  const [checkMenu, setCheckMenu] = useState(false);
  const [isErrorPage, setIsErrorPage] = useState(false);
  const [isNotShowing, setIsNotShowing] = useState(false);

  useEffect(() => {
    if (
      props.location.pathname.includes("/404") ||
      props.location.pathname.includes("/privacy-policy")
    ) {
      setIsNotShowing(true);
    }
    if (props.location.pathname.includes("/404")) {
      setIsErrorPage(true);
    }
  });

  const isProcessPage = props.location.pathname.includes("/production-process");

  return (
    <div>
      <GlobalStyle scrollLocked={isProcessPage} />
      <ScreenWidthProvider>
        <Main>
          <Header
            currentPathname={props.location.pathname}
            checkMenu={checkMenu}
            setCheckMenu={setCheckMenu}
          />
          {children}
          {!isNotShowing && (
            <BottomNav
              currentPathname={props.location.pathname}
              checkMenu={checkMenu}
            />
          )}
          {!isErrorPage && <Footer />}
        </Main>
      </ScreenWidthProvider>
    </div>
  );
}

export default Layout;
