import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import { Container, GridContainer, H3, A, P } from "@util/standard";
import { Query } from "@graphql-types";
import { MOBILE_BREAKPOINT } from "@util/constants";

interface Props { }

const StyledImg = styled(Img) <{ width: string; mobileWidth: string; }>`
  width: 100%;
  ${({ width }) => width && `width: ${width}`};
  height: 100%;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    margin: auto;
    img {
      object-fit: contain !important;
    }
  }
`;

const TextLink = styled(A)`
  color: white;
  opacity: 0.3;
  font-size: 12px;
  text-decoration: underline;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const CopyRightContainer = styled(Container)`
  opacity: 0.5;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const TitleStyle = styled(H3)`
  margin: 10px 0px 0px 0px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0px 0px 10px 0px
  }
`;

const Footer = (props: Props) => {
  const { sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        privacyPolicyLink {
          url
          linktext
        }
        emailLink {
          url
          linktext
        }
        footerLeftSideText
        footerRightSideLink
        footerSecondRightSideLink
        footerRightSideLogo {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
          }
        }
        footerSecondRightSideLogo {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
          }
        }
      }
    }
  `);

  if (sanityFooter == null) {
    return null;
  }

  const {
    privacyPolicyLink,
    footerRightSideLink,
    footerSecondRightSideLink,
    emailLink,
    footerLeftSideText,
    footerRightSideLogo,
    footerSecondRightSideLogo,
  } = sanityFooter;
  return (
    <GridContainer
      gridTemplateColumns="repeat(2,1fr)"
      height="117px"
      width="100%"
      backgroundColor="navy"
      tabletRepeat={2}
      mobileRepeat={2}
    >
      <Container margin="auto 0px auto 15%" mobileMargin="48px auto auto auto">
        <TitleStyle
          opacity={0.5}
          mobileFontSize={15}
          color="white"
          fontSize={20}
        >
          {footerLeftSideText?.toUpperCase()}
        </TitleStyle>
        <Link to="/privacy-policy">
          <TextLink>{privacyPolicyLink?.linktext}</TextLink>
        </Link>
        <TextLink>{emailLink?.linktext}</TextLink>
        <CopyRightContainer>
          <P fontSize={12}>© Copyright 2021 New Zealand's Aluminium Smelter. All rights reserved.</P>
        </CopyRightContainer>
      </Container>
      <GridContainer
        gridTemplateColumns="repeat(2,1fr)"
        mobileRepeat={2}
        height="50px"
        width="300px"
        mobileWidth="100%"
        margin="auto 0px auto 60%"
        ipadProMargin="auto 0px auto 42%"
        tabletMargin="auto 0px auto 25%"
        mobileMargin="auto 15% auto 0px"
      >
        <a href={`${footerRightSideLink}`} target="_blank">
          <StyledImg
            fluid={footerRightSideLogo?.asset?.fluid}
            width="115px"
            mobileWidth="95px"
          />
        </a>
        <a href={`${footerSecondRightSideLink}`} target="_blank">
          <StyledImg
            fluid={footerSecondRightSideLogo?.asset?.fluid}
            width="80px"
            mobileWidth="65px"
          />
        </a>
      </GridContainer>
    </GridContainer>
  );
};

export default Footer;
