import React, { useState } from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { icons, MOBILE_BREAKPOINT, colors } from "@util/constants";
import { icon } from "@util/types";

interface Props {
  buttonText: string | null | undefined;
  url?: string | null | undefined;
  margin?: string;
  width?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  tabletMargin?: string;
  mobileMargin?: string;
  reversed?: boolean;
  icon?: icon;
  onClick?: () => void | null | undefined;
  darkVersion?: boolean;
  fontSize?: number;
  imgWidth?: number;
  imgHeight?: number;
  textColor?: string;
  zIndex?: number;
}

const StyledContainer = styled(Container)<{
  reverse?: boolean;
  zIndex?: number;
}>`
  display: flex;
  flex-wrap: wrap;
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : `z-index:999`)};
  ${({ reverse }) => reverse && `flex-direction: row-reverse;`};
`;

const ButtonText = styled(P)<{
  hoverOn: boolean;
  reverse?: boolean;
  darkVersion?: boolean;
  fontSize?: number;
  textColor?: string;
}>`
  opacity: 1;
  display: inline-block;
  margin: auto;
  margin-right: 15px;
  margin-left: 0px;
  ${({ darkVersion, textColor }) =>
    textColor && darkVersion
      ? `color: ${textColor};`
      : textColor && !darkVersion
      ? `color: ${colors.white};`
      : darkVersion && `color: ${colors.black};`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`};

  ${({ reverse }) => reverse && `margin-left: 15px; margin-right: 0px;`};

  ${({ hoverOn }) => hoverOn && `opacity: 0.5;`}
  transition: all 0.2s;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 12px;
  }
`;

const Icon = styled.img<{
  hoverOn: boolean;
  darkVersion: boolean | undefined;
  imgWidth?: number;
  imgHeight?: number;
}>`
  display: inline-block;
  margin: auto;
  opacity: 1;
  ${({ hoverOn }) => hoverOn && `opacity: 0.5;`}
  transition: all 0.2s;
  ${({ imgWidth }) => imgWidth && `width: ${imgWidth}px;`}
  ${({ imgHeight }) => imgHeight && `height: ${imgHeight}px;`}

  ${({ darkVersion }) => darkVersion && `filter: brightness(0.5);`};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 18px;
  }
`;

const LinkStyle = styled.a`
  text-decoration: none;
`;

function IconButton(props: Props) {
  const [hoverEffectOn, setHoverEffect] = useState(false);
  const {
    buttonText,
    url,
    margin,
    mobileMargin,
    reversed,
    icon,
    onClick,
    darkVersion,
    fontSize,
    imgWidth,
    imgHeight,
    tabletMargin,
    zIndex,
    textColor,
  } = props;

  const Child = () => (
    <StyledContainer reverse={reversed} zIndex={zIndex}>
      <ButtonText
        hoverOn={hoverEffectOn}
        reverse={reversed}
        darkVersion={darkVersion}
        fontSize={fontSize}
        textColor={textColor}
      >
        {buttonText?.toUpperCase()}
      </ButtonText>
      <Icon
        hoverOn={hoverEffectOn}
        src={icon ? icons[icon] : icons.rightIcon}
        darkVersion={darkVersion}
        imgWidth={imgWidth}
        imgHeight={imgHeight}
      />
    </StyledContainer>
  );

  return (
    <Container
      cursor="pointer"
      width="max-content"
      margin={margin ? margin : ""}
      mobileMargin={mobileMargin}
      tabletMargin={tabletMargin}
      onClick={onClick}
    >
      {onClick && !url ? (
        <Child />
      ) : (
        <LinkStyle href={url ?? "/"}>
          <Child />
        </LinkStyle>
      )}
    </Container>
  );
}

export default IconButton;
