import React, { Dispatch, ReactNode, SetStateAction } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import { colorsRGB } from "@util/constants";
import { IconButton } from "./sub";
import { Container } from "@util/standard";
import { Color } from "@util/types";
interface Props {
  children: ReactNode;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  width?: string;
  height?: string;
  padding?: string;
  overflow?: string;
  overflowX?: string;
  background?: Color;
}

const CloseIcon = styled(Container)`
  position: absolute;
  top: 27px;
  left: 25px;
  cursor: pointer;
  z-index: 2;
`;

const ModalContainer = styled(Container)``;

function ModalBox(props: Props) {
  const {
    children,
    setVisible,
    visible,
    width,
    height,
    overflow,
    background,
    overflowX,
    padding,
  } = props;

  const modalBaseStyle = {
    content: {
      top: "50%",
      left: "50%",
      width: width ?? "80%",
      height: height ?? "90%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      overflow: overflow ?? "scroll",
      overflowX: overflowX ?? "scroll",
      background: background ?? "#050D23",
      padding: padding ?? "100px",
    },
    overlay: {
      backgroundColor: `${colorsRGB.black(0.76)}`,
      zIndex: 100,
    },
  };

  return (
    <ModalContainer>
      <Modal style={modalBaseStyle} isOpen={visible} ariaHideApp={false}>
        <CloseIcon>
          <IconButton
            darkVersion={background === "white"}
            reversed
            icon="closeIcon"
            buttonText="Close"
            onClick={() => setVisible(false)}
          />
        </CloseIcon>
        {children}
      </Modal>
    </ModalContainer>
  );
}

export default ModalBox;
