import { useRef, useEffect } from "react";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function formatStringToUrl(string: string) {
  const convertedText = string.replace(/\s+/g, "-").toLowerCase();
  return convertedText;
}

export function formatUrlToString(string: string) {
  const hashlessText = string.substring(1);
  const convertedText = hashlessText.replace(new RegExp("-", "g"), " ");
  return convertedText;
}

export function copyToClipboard(string: string) {
  return navigator.clipboard.writeText(string);
}

export function scrollToItem(id: string) {
  const selectedItem = document.getElementById(id);
  selectedItem?.scrollIntoView({ behavior: "smooth" });
}
