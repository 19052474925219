import React, { useState } from "react";
import styled from "styled-components";

import { copyToClipboard } from "@util/helper";
import { P } from "@util/standard";
import { JustifyContent } from "@util/types";
import { animationTypes, colors } from "@util/constants";
import AnimationContainer from "./animationContainer";
interface Props {
  alignment?: JustifyContent;
  id?: string;
  category?: string;
  margin?: string;
  isDark?: boolean;
  copyEntireUrl?: boolean;
}

const ShareButtonWrapper = styled.div<{ isDark?: boolean }>`
  ${({ isDark }) =>
    isDark
      ? `border: 1.5px solid white;`
      : `border: 1.5px solid ${colors.darkGrey};`};

  border-radius: 20px;
  padding: 0px 10%;
  text-align: center;
  min-width: 59px;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.2s;
`;

const Wrapper = styled.div<{ alignment: string; margin?: string }>`
  display: flex;
  width: 100%;
  ${({ margin }) => (margin ? `margin: ${margin};` : `margin: 20px 0px;`)};
  ${({ alignment }) => alignment && `justify-content: ${alignment};`};
`;

const SuccessMsg = styled.p`
  position: fixed;
  bottom: 5%;
  right: 3%;
  padding: 22px 25px;
  color: white;
  font-size: 14px;
  background-color: ${colors.darkNavy};
  border-radius: 25px;
  z-index: 5;
  text-align: center;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fadeIn 2s;
  animation-fill-mode: forwards;
`;

function ShareButton(props: Props) {
  const { alignment, margin, isDark, id, category, copyEntireUrl } = props;

  const [responseMessage, setResponseMessage] = useState<string>();

  const successMessage = "Link has been copied to Clipboard";
  const errorMessage = "Failed to copy link to Clipboard";

  const copyUrl = (url: string) => {
    copyToClipboard(url).then(
      () => {
        setResponseMessage(successMessage);
        setTimeout(() => {
          setResponseMessage(null);
        }, 3000);
      },
      () => {
        setResponseMessage(errorMessage);
      }
    );
  };

  const handleShare = () => {
    responseMessage === successMessage
      ? setResponseMessage(successMessage)
      : null;

    if (typeof window !== "undefined") {
      if (category) {
        const formattedUrl =
          window.location.origin +
          window.location.pathname +
          "?" +
          category +
          "#" +
          id;
        copyUrl(formattedUrl);
      } else if (!copyEntireUrl) {
        const formattedUrl =
          window.location.origin + window.location.pathname + "#" + id;
        copyUrl(formattedUrl);
      } else {
        copyUrl(window.location.href);
      }
    }
  };

  return (
    <>
      <Wrapper alignment={alignment ?? "flex-start"} margin={margin}>
        <ShareButtonWrapper isDark={isDark} onClick={() => handleShare()}>
          <P color={isDark ? "white" : "black"} fontSize={15} margin="8px 0px">
            SHARE
          </P>
        </ShareButtonWrapper>
        {responseMessage && (
          <SuccessMsg>
            <AnimationContainer animationType={animationTypes.slideInRight}>
              {responseMessage}
            </AnimationContainer>
          </SuccessMsg>
        )}
      </Wrapper>
    </>
  );
}

export default ShareButton;
