import React, { createContext, ReactNode, useEffect, useState } from "react";

import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from "@util/constants";

interface State {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
}

export type ScreenSizeContextType = {
  screenSize: State;
};

const defaultState = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
};

export const ScreenSizeContext = createContext<ScreenSizeContextType>({
  screenSize: defaultState,
});

interface Props {
  children: ReactNode;
}

const ScreenWidthProvider = ({ children }: Props) => {
  const [screenSize, setScreenSize] = useState<State>(defaultState);

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenSize({
        ...defaultState,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenSize({
        ...defaultState,
        isTabletWidth: true,
      });
      return;
    }

    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenSize({
        ...defaultState,
        isLaptopWidth: true,
      });
      return;
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkScreenWidth();
      window.addEventListener("resize", checkScreenWidth);
    }

    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return (
    <ScreenSizeContext.Provider value={{ screenSize }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export default ScreenWidthProvider;
