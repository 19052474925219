import React, { Dispatch, SetStateAction, useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import { Container, GridContainer, H2, P } from "@util/standard";
import { Query } from "@graphql-types";
import {
  animationTypes,
  colors,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  IMAC_BREAKPOINT,
} from "@util/constants";
import { IconButton, AnimationContainer } from "@sub";

const SideMenuWrapper = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
`;

const StyledImg = styled(Img)`
  background-color: ${colors.navy};
  img {
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: fadeIn 1s;
  }

  width: 100%;
  height: 100%;
  z-index: 0;
`;

const MenuItemWrapper = styled.div<{ isFirstOrLast: boolean }>`
  z-index: 1;
  background: rgba(6, 15, 37, 0.8);
  border-right: 3px solid white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p,
  img {
    opacity: 0;
  }
  .menuP {
    width: 90%;
    min-width: 270px;
    min-height: 30px;
  }
  p {
    font-size: 15px;
  }

  &:hover {
    p,
    img {
      transform: translateY(-7vh);
      opacity: 1;
      transition: all 0.5s;
    }
    h2 {
      transform: translateY(-5vh);
      opacity: 1;
      transition: all 0.5s;
    }
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    .menuP,
    p,
    img {
      opacity: 1;
    }

    &:hover {
      h2,
      .menuP,
      p,
      img {
        transform: translateY(0px);
      }
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: left;
    h2,
    p,
    img {
      opacity: 1;
    }

    .menuP {
      display: none;
    }
    border-right: none;
    border-bottom: 3px solid white;

    &:hover {
      h2,
      .menuP,
      p,
      img {
        transform: translateY(0px);
      }
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
    h2 {
      width: 100%;
      font-size: 18px;
      line-height: 21px;
    }

    p {
      font-size: 12px;
    }
  }
`;

const MenuTitle = styled(H2)`
  opacity: 0.5;
  font-size: 33px;
  letter-spacing: 3px;
  font-family: "bold";
  color: ${colors.white};
  line-height: 35px;
  margin-bottom: 10px;
  transition: all 0.5s;

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    margin-bottom: 25px;
  }
`;

interface Props {
  setSideMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function HeaderSideMenu({ setSideMenuOpen }: Props) {
  const [currentHoverIndex, updateCurrentHoverIndex] = useState(0);

  const { sanityHeader }: Query = useStaticQuery(graphql`
    {
      sanityHeader {
        headerSideMenu {
          _key
          menuItemTitle
          menuItemDescription
          menuItemLink {
            linktext
            url
            newwindow
          }
          menuItemBackgroundImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
        }
      }
    }
  `);

  if (sanityHeader == null) {
    return null;
  }

  const { headerSideMenu } = sanityHeader;

  return (
    <SideMenuWrapper>
      <GridContainer
        gridTemplateColumns="repeat(4,1fr)"
        tabletRepeat={1}
        position="absolute"
        width="100%"
        height="100%"
      >
        {headerSideMenu?.map((menuItem, index) => {
          if (menuItem == null) {
            return null;
          }
          const { _key, menuItemTitle, menuItemDescription, menuItemLink } =
            menuItem;

          const isFirst = index === 0;
          const isLast = index === headerSideMenu.length - 1;

          return (
            <MenuItemWrapper
              isFirstOrLast={isFirst || isLast}
              key={_key}
              onMouseEnter={() => updateCurrentHoverIndex(index)}
              onClick={() => {
                navigate(`${menuItemLink?.url}`);
                setSideMenuOpen(false);
              }}
            >
              <AnimationContainer
                animationType={animationTypes.slideInBottom}
                delay={500}
              >
                <Container
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  tabletAlignItems="flex-start"
                  width="232px"
                  cursor="pointer"
                >
                  <MenuTitle>{menuItemTitle?.toUpperCase()}</MenuTitle>
                  <P
                    className="menuP"
                    tabletWidth={isFirst ? "100%" : "73%"}
                    fontSize={14}
                  >
                    {menuItemDescription}
                  </P>
                  <IconButton
                    buttonText={menuItemLink?.linktext}
                    url={menuItemLink?.url}
                  />
                </Container>
              </AnimationContainer>
            </MenuItemWrapper>
          );
        })}
      </GridContainer>
      <StyledImg
        fluid={
          headerSideMenu[currentHoverIndex]?.menuItemBackgroundImage?.asset
            ?.fluid
        }
      />
    </SideMenuWrapper>
  );
}

export default HeaderSideMenu;
