// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-product-tsx": () => import("./../../../src/pages/our-product.tsx" /* webpackChunkName: "component---src-pages-our-product-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-production-process-tsx": () => import("./../../../src/pages/production-process.tsx" /* webpackChunkName: "component---src-pages-production-process-tsx" */),
  "component---src-templates-people-inner-tsx": () => import("./../../../src/templates/peopleInner.tsx" /* webpackChunkName: "component---src-templates-people-inner-tsx" */)
}

