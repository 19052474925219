import React, { useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";

import { GridContainer, Container, H3 } from "@util/standard";
import { Query } from "@graphql-types";
import {
  colors,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { IconButton, Image } from "./sub";
import {
  PreviewContainer,
  StyledContainer,
  BackgroundContainer,
  BackgroundOverlay,
} from "./shared.styles";

interface Props {
  currentPathname: string;
  checkMenu: boolean;
}

const ItemContainer = styled.div<{ isLast: boolean; }>`
  display: grid;
  z-index: 5;
  height: 248px;
  border-right: 3px solid white;
  width: 100%;
  h3
  div {
    transition: all 0.2s;
  }

  &:hover {
    h3 {
      opacity: 1;
      height: 30%;
    }
    div {
      opacity: 1;
    }
  }
  transition: all 0.2s ease-in;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-right: none;
    ${({ isLast }) =>
    isLast
      ? `border-bottom: 0px solid transparent;`
      : `border-bottom: 3px solid white;`};
    &:hover {
      h3 {
        opacity: 1;
        height: 40%;
      }
    }
    transition: all 0.7s ease-in;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    &:hover {
      h3 {
        opacity: 1;
        height: 35%;
      }
    }
    transition: all 0.7s ease-in;
  }
`;

const MenuTitle = styled(H3)`
  color: ${colors.white};
  opacity: 0.3;
  margin: auto auto 10px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: auto auto 0px auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: auto auto 0px auto;
  }
`;

function BottomNav(props: Props) {
  const { currentPathname, checkMenu } = props;
  const { sanityHeader, sanityBottomNav }: Query = useStaticQuery(graphql`
    {
      sanityHeader {
        headerSideMenu {
          _key
          menuItemTitle
          menuItemLink {
            url
          }
        }
      }
      sanityBottomNav {
        bottomNavImage {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  if (sanityHeader == null) {
    return null;
  }
  if (sanityBottomNav == null) {
    return null;
  }

  const { headerSideMenu } = sanityHeader;
  const { bottomNavImage } = sanityBottomNav;

  const [gradientEffectOn, setGradientEffectOn] = useState<
    number | undefined
  >();

  const filtered = headerSideMenu?.filter((menu) => {
    const checkValue = menu?.menuItemLink?.url!;
    return currentPathname !== checkValue;
  });

  const navToRender = currentPathname.includes("/people")
    ? filtered?.filter((menu) => menu?.menuItemLink?.url !== "/")
    : currentPathname.includes("/history")
      ? filtered?.filter((menu) => !menu?.menuItemLink?.url?.includes("/history"))
      : currentPathname.includes("/production-process")
        ? filtered?.filter(
          (menu) => !menu?.menuItemLink?.url?.includes("/production-process")
        )
        : filtered;

  return (
    <Container
      width="100%"
      position="relative"
      margin="0 -3px 0 -3px"
      tabletWidth="auto"
    >
      <PreviewContainer height="100%" mobileHeight="100%">
        <BackgroundContainer>
          <Image
            isBackground
            backgroundStyle={{
              width: "100%",
              height: "100%",
            }}
            fluid={bottomNavImage?.asset?.fluid as FluidObject}
            objectFit="cover"
          />
          <BackgroundOverlay gradientHover={gradientEffectOn} />
        </BackgroundContainer>
        <GridContainer
          gridTemplateColumns="repeat(3,1fr)"
          tabletRepeat={1}
          width="100%"
          zIndex={1}
        >
          {!checkMenu &&
            navToRender?.map((menuItem, index) => {
              return (
                <ItemContainer
                  key={menuItem?._key}
                  isLast={index === navToRender.length - 1}
                  onMouseEnter={() => setGradientEffectOn(index)}
                  onMouseLeave={() => setGradientEffectOn(99)}
                  onClick={() => navigate(`${menuItem?.menuItemLink?.url}`)}
                >
                  <MenuTitle
                    fontSize={23}
                    letterSpacing="1px"
                    ipadProFontSize={27}
                  >
                    {menuItem?.menuItemTitle?.toUpperCase()}{" "}
                  </MenuTitle>

                  <StyledContainer hoverEffect={gradientEffectOn === index}>
                    <IconButton
                      margin="auto"
                      buttonText="more"
                      fontSize={15}
                      imgHeight={19}
                      imgWidth={19}
                      url={menuItem?.menuItemLink?.url}
                    />
                  </StyledContainer>
                </ItemContainer>
              );
            })}
        </GridContainer>
      </PreviewContainer>
    </Container>
  );
}

export default BottomNav;
